<template>
    <div class="row">
  
      <div class="col-12">
  
        <card>
          <div slot="header" class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap">
            <h4 class="card-title">Subscriptions List</h4>
            <div class="d-flex justify-content-start">
            </div>
  
          </div>
  
          <div>
            <general-data-table
              ref="table"
              :method="'POST'"
              :row-key="'id'"
              :key="componentKey"
              :api-url="'subscriptions/index'">
              
              <el-table-column
              :min-width="120"
              align="center"
              label="Status">
              <template slot-scope="props">
                <p :class="props.row.status_formatted.color+' status'"> {{ props.row.status_formatted.status }}  </p>

              </template>
            </el-table-column>

              <el-table-column v-for="column in tableColumns"
                               :key="column.label"
                               :min-width="column.minWidth"
                               :align="column.align"
                               :sortable="column.sortable"
                               :prop="column.value"
                               :label="column.label">


                <!-- <template slot-scope="scope">

                    <div v-if="column.value === 'status_formatted'">
                    <p :class="scope.row[column.value.color]"> {{ scope.row[column.value.status] }}  </p>
                    </div>
                    <span v-else>{{ scope.row[column.value] }}</span>

                </template>               -->
              </el-table-column>

              
            
              
  
              <el-table-column
                :min-width="120"
                align="center"
                label="Actions">
                <template slot-scope="scope">
  
                  <router-link v-if="$store.getters['auth/haveOneOfPermissions'](['subscriptions/view'])"
                               v-tooltip.top-center="'View'"
                               class="btn-warning btn-simple btn-link"
                               :to="'/subscriptions/view/'+scope.row.id">
                    <i class="fa fa-eye"></i></router-link>
  
                </template>
              </el-table-column>
  
            </general-data-table>
          </div>
  
        </card>
  
  
      </div>
  
    </div>
  </template>

  <style>
.status{
  display: inline-block;
  padding: 0.25em 0.4em;
  font-size: 75%;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25rem;
  -webkit-transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,-webkit-box-shadow .15s ease-in-out;
  transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,-webkit-box-shadow .15s ease-in-out;
  transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
  transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out,-webkit-box-shadow .15s ease-in-out;
}
.yellow{
    background-color: yellow;
}
.green {
    background-color: greenyellow;
}
.red{
  background-color: red;
  color: #fff;
}
.grey{
  background-color: grey;
    color: #fff;
}
</style>
  <script>
  import {TableColumn} from 'element-ui'
  import {NewPagination as NLPagination, Switch as LSwitch} from '@/components'
  import GeneralDataTable from "@/components/GeneralDataTable";
  import FgSelect from "@/components/Inputs/formGroupSelect";
  import ExportModal from '@/components/ExportModal/ExportModal.vue';
  
  
  export default {
    components: {
      NLPagination,
      [TableColumn.name]: TableColumn,
      LSwitch,
      GeneralDataTable,
      FgSelect,
      ExportModal
    },
  
    data() {
  
      return {
        tableColumns: [
          {label: 'Parent Name', value: 'parent.full_name', minWidth: '200', align: 'center'},
          {label: 'Child Name', value: 'child.full_name', minWidth: '200', align: 'center'},
          {label: 'Price', value: 'price', minWidth: '200', align: 'center'},
          {label: 'Created date', value: 'created_at', minWidth: '200', align: 'center'},
          {label: 'Start date', value: 'start_date', minWidth: '200', align: 'center'}
        ],
        modals: {
          basic: false,
          centered: false,
          custom: false,
          confirm: false
        },
        deleteModalVisibility: false,
        toDeleteId: undefined,
        componentKey: 0,
      }
  
    },
  
    methods: {
      openModal(name) {
        this.modals[name] = true
      },
      closeModal(name) {
        this.modals[name] = false
      },
  
    },
  
  }
  </script>
  